/* Hide scrollbar for all elements */
::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

a {
  text-decoration: none;
}

.gallery {
    display: flex;
    left: calc(50% - 19rem);
}

.growth-card {
    position: relative;
    left: 0;
    width: 9.5rem;
    height: 3.8rem;
    background-color: var(--background-card);
    border-radius: 0.5rem;
    transition: 1s ease-in-out;
    overflow: hidden;
    /* box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5); */
}

.growth-card-content{
  position: relative;
  height: 3.8em;
}

.growth-card-title {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  position: absolute;
  bottom: 1em;
}

.growth-card-info {
  position: relative;
  display: none;
}

.growth-card:not(:first-child) {
    margin-left: -3rem;
}


.growth-card-content:hover {
  height: 13.8rem;
  position: fixed;
  bottom: -1em;
}

.growth-card-content:hover .growth-card-title{
    transform: translateY(-1rem);
    font-weight: bold;
    cursor: pointer;
}

.growth-card-content:hover  .growth-card-info {
    display: block;
}

.growth-card:hover ~ .card {
    left: 3.5rem;
}

.growth-card img {
    height: 10rem;
    object-fit: cover;
    border-radius: 0.25rem;
}


.mi-card-title {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.mi-card {
    position: relative;
    left: 0;
    width: 9rem;
    height: 4rem;
    background-color: var(--background-card);
    border-radius: 0.5rem;
    transition: 1s ease-in-out;
    overflow: hidden;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
}

.mi-card:not(:first-child) {
    margin-left: -1rem;
}

.mi-card:hover {
    transform: translateY(1rem);
    font-weight: bold;
    cursor: pointer;
}

.mi-card:hover ~ .card {
    left: 3.5rem;
}

.mi-card img {
    height: 10rem;
    object-fit: cover;
    border-radius: 0.25rem;
}