::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
}

.gallery {
  display: flex;
  left: calc(50% - 19rem);
}

.growth-card {
  background-color: var(--background-card);
  border-radius: .5rem;
  width: 9.5rem;
  height: 3.8rem;
  transition: all 1s ease-in-out;
  position: relative;
  left: 0;
  overflow: hidden;
}

.growth-card-content {
  height: 3.8em;
  position: relative;
}

.growth-card-title {
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-clip: text;
  position: absolute;
  bottom: 1em;
}

.growth-card-info {
  display: none;
  position: relative;
}

.growth-card:not(:first-child) {
  margin-left: -3rem;
}

.growth-card-content:hover {
  height: 13.8rem;
  position: fixed;
  bottom: -1em;
}

.growth-card-content:hover .growth-card-title {
  cursor: pointer;
  font-weight: bold;
  transform: translateY(-1rem);
}

.growth-card-content:hover .growth-card-info {
  display: block;
}

.growth-card:hover ~ .card {
  left: 3.5rem;
}

.growth-card img {
  object-fit: cover;
  border-radius: .25rem;
  height: 10rem;
}

.mi-card-title {
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-clip: text;
}

.mi-card {
  background-color: var(--background-card);
  border-radius: .5rem;
  width: 9rem;
  height: 4rem;
  transition: all 1s ease-in-out;
  position: relative;
  left: 0;
  overflow: hidden;
  box-shadow: 0 5px 12px #00000080;
}

.mi-card:not(:first-child) {
  margin-left: -1rem;
}

.mi-card:hover {
  cursor: pointer;
  font-weight: bold;
  transform: translateY(1rem);
}

.mi-card:hover ~ .card {
  left: 3.5rem;
}

.mi-card img {
  object-fit: cover;
  border-radius: .25rem;
  height: 10rem;
}
/*# sourceMappingURL=index.3b6d919e.css.map */
